import { useEffect } from "react"
import { useI18next } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

const NotFoundPage = () => {
  const { navigate } = useI18next();

  useEffect(() => {
    navigate('/');
  }, []);

  return null;
}

export default NotFoundPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;